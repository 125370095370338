import React, { Component } from 'react';
class Admin extends Component {
    state = {  } 
    render() { 
        return (<div>
            admin
        </div>);
    }
}
 
export default Admin;