import {Route, Redirect} from "react-router-dom"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
class ProtectedRoute extends Component {
    state = {  }
   
    render() {
        Axios.post('https://hosting.breezelabs.co.uk/api/stats/page-visit', {referer: document.referrer ? document.referrer : 'searchbar', site: window.location.href})

        let path = window.location.href.substring(window.location.origin.length)
        if(this.props.user){
            return (
                <div path={path}> {this.props.children} </div>
            )
        }
        else{
            return (
                <Redirect  to={{pathname: '/login', state:{last:path}}}/>
            )
        }    
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
  }
 
export default connect(mapStateToProps)(ProtectedRoute);
