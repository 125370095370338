import React, { Component } from 'react';
class Forbidden404 extends Component {
    state = {  }
    render() { 
        return ( 
            <h1>Forbidden 404</h1>
         );
    }
}
 
export default Forbidden404;