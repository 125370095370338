import {Route} from "react-router-dom"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Forbidden403 from '../../pages/forbidden403.js'


class ProtectedRoute extends Component {
    state = {  }
    render() {
        let path = window.location.href.substring(window.location.origin.length)

        if(this.props.user && this.props.user.admin === 'true'){
            return (
                <Route {...this.props} path={this.props.path}> {this.props.children} </Route>
            )
        }
        else{
            return (
                <Forbidden403/>
            )
        }    
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
  }
export default connect(mapStateToProps)(ProtectedRoute);
  