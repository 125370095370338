import initialState from './initialState'
import axios from 'axios'
import { SET_USER } from '../actions/actionsTypes'

export default function user(state=initialState.users, action){
    let newState
    switch(action.type){
        case SET_USER:
            newState = action.payload.user
            localStorage.setItem('jwtToken', action.payload.token)
            axios.defaults.headers.common['x-auth-token'] = action.payload.token
            return newState

        default:
            return state
    }
}