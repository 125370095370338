import React, { Component } from 'react';
import {Helmet} from "react-helmet"
class NotFound404 extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
            <Helmet>
            <meta name="robots" content="noindex" />           
            </Helmet>
            <h1>Not Found 404</h1>
            <a href="https://f1standingscalculator.com/sitemap.xml">Sitemap</a>
            </div>
         );
    }
}
 
export default NotFound404;