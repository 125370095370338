import React, { Component } from 'react';
class Account extends Component {
    state = {  } 
    render() { 
        return (<div>
            Account
        </div>);
    }
}
 
export default Account;