import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { connect } from 'react-redux';
import { setUser } from './actions/userActions'
import Account from './pages/account';
import Admin from './pages/admin';
import NotFound404 from './pages/notFound404';

import Axios from 'axios'
 

import ProtectedRoute from './components/routes/protectedRoute'
import PublicRoute from './components/routes/publicRoute'
import AdminRoute from './components/routes/adminRoute'

import "./styles.css"

class App extends Component {
  state = { maintainance: false }
  componentDidMount() {
    Axios.defaults.headers.common['x-auth-token'] = localStorage.jwtToken
    // Axios.get('/api/user/user').then(res => {
    //   if (res.data.success) {
    //     this.props.setUser({ user: res.data.user, token: localStorage.jwtToken })
    //   }
    // })
  }

  render() {
    if (this.state.maintainance) {
      if (this.props.user && this.props.user.email !== 'thomas.breeze@icloud.com') {
        return <h1 style={{ margin: "100px" }}>This service is down for scheduled maintainance. Please check back soon.</h1>
      }
    }
    return (
      <div style={{background: "rgb(59, 74, 88)", minHeight: "100vh"}}>
    
        <Router>
          <div style={{ paddingTop: '100px', paddingBottom: "120px" }}>
            <Switch>
              <PublicRoute exact={true} path="/"><Redirect to='/calculator' /></PublicRoute>
              
              <ProtectedRoute path="/account"> <Account /> </ProtectedRoute>
              
              <AdminRoute exact={true} path='/admin'><Admin /></AdminRoute>

              <Route><NotFound404 /></Route>
            </Switch>

          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => { dispatch(setUser(data)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);